var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
          backgroundColor: _vm.colors[_vm.c][_vm.s]
        }),attrs:{"dark":Number(_vm.s) > 400}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.label)+" ")])]}}])},[_c('div',{style:({ padding: '0.25rem', backgroundColor: 'white' })},_vm._l((_vm.colors),function(color,k){return _c('div',{key:k,style:({ paddingBottom: '0.25rem', display: 'grid', gridTemplateColumns: 'repeat(10, minmax(0, 1fr))', gap: '0.25rem' })},_vm._l((color),function(shade,key){return _c('div',{key:key,style:({
            backgroundColor: shade,
            width: '1.5rem',
            height: '1.5rem'
          }),on:{"click":function($event){return _vm.selectcolor(k, key)}}})}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }