<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          หน้าหลัก (การ Upload เกี่ยวกับรูปภาพจะใช้เวลา 1 นาที) <v-spacer></v-spacer> <v-btn
            color="success"
            @click="savemainpage"
          >
            Save
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Upload Logo
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.logo"
              contain
              width="200"
              height="32"
              :src="'https://cms.deepcdn.net/' + mainpage.logo"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="logotmp"
              label="400x65"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!logotmp"
              color="primary"
              @click="uploadlogo"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Upload Mini Logo
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.minilogo"
              contain
              width="100"
              height="50"
              :src="'https://cms.deepcdn.net/' + mainpage.minilogo"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="minilogotmp"
              label="200x100"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!minilogotmp"
              color="primary"
              @click="uploadminilogo"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Upload Big Logo
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.biglogo"
              contain
              :src="'https://cms.deepcdn.net/' + mainpage.biglogo"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="biglogotmp"
              label="790x390"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!biglogotmp"
              color="primary"
              @click="uploadbiglogo"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Domain
          </v-col>
          <v-col>
            <v-text-field
              disabled
              label="Domain เว็บ"
              dense
              hide-details
              outlined
              :value="mainpage.domain"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col
          class="ml-2"
          cols="6"
        >
          Color
        </v-col>
        <v-row class="ma-0 pb-1 px-2">
          <v-col
            v-for="zonetext in zonetexts"
            :key="zonetext.value"
          >
            <colorpellet
              :type="zonetext.value"
              :label="zonetext.label"
              :c="mainpage[zonetext.value].c"
              :s="mainpage[zonetext.value].s"
              @scolor="scolor"
            />
          </v-col>
        </v-row>
        <!-- <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Color
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="mainpage.primary"
              :items="colors"
              item-text="label"
              item-value="value"
              label="สีหลัก"
              dense
              hide-details
              outlined
            >
              <template v-slot:[`item`]="{ item }">
                <v-row class="px-3">
                  <div
                    class="mr-2"
                    :style="`background-color: ${item.code}; width:33%;`"
                  >
                    &nbsp;
                  </div>
                  <div>
                    {{ item.label }}
                  </div>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="mainpage.secondary"
              :items="colors"
              item-text="label"
              item-value="value"
              label="สีรอง"
              dense
              hide-details
              outlined
            >
              <template v-slot:[`item`]="{ item }">
                <v-row class="px-3">
                  <div
                    class="mr-2"
                    :style="`background-color: ${item.code}; width:33%;`"
                  >
                    &nbsp;
                  </div>
                  <div>
                    {{ item.label }}
                  </div>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="mainpage.menubar"
              :items="menubars"
              item-text="label"
              item-value="value"
              label="สีเมนูด้านล่าง"
              dense
              hide-details
              outlined
            >
              <template v-slot:[`item`]="{ item }">
                <v-row class="px-3">
                  <div
                    class="mr-2"
                    :style="`background-color: ${item.code}; width:33%;`"
                  >
                    &nbsp;
                  </div>
                  <div>
                    {{ item.label }}
                  </div>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="mainpage.textcolor"
              :items="textcolors"
              item-text="label"
              item-value="value"
              label="สีตัวหนังสือ"
              dense
              hide-details
              outlined
            >
              <template v-slot:[`item`]="{ item }">
                <v-row class="px-3">
                  <div
                    class="mr-2"
                    :style="`background-color: ${item.code}; width:33%;`"
                  >
                    &nbsp;
                  </div>
                  <div>
                    {{ item.label }}
                  </div>
                </v-row>
              </template>
            </v-select>
          </v-col>
        </v-row> -->
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Title
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.title"
              label="ชื่อหัวเว็บ"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Welcome message
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.wcmessage"
              label="ข้อความต้อนรับ"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Description
          </v-col>
          <v-col>
            <v-textarea
              v-model="mainpage.description"
              label="คำอธิบายเว็บ* (ไม่ควรเกิน 120 ตัวอักษร)"
              maxlength="120"
              dense
              hide-details
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Keywords
          </v-col>
          <v-col>
            <v-combobox
              v-model="mainpage.keywords"
              multiple
              chips
              deletable-chips
              label="Keywords หน้าหลัก"
              hide-details
              outlined
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Entry
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.entry"
              disabled
              label="Link ทางเข้า"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Register
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.register"
              disabled
              label="Link สมัคร"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Line@
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.lineadd"
              label="Link Line@"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="2">
            <v-btn
              color="info"
              @click="getlineadd"
            >
              ดึงข้อมุล
            </v-btn>
          </v-col> -->
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Google Tag
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.gtag"
              label="Google Analytics Tag"
              placeholder="G-XXXXXXX"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Search Console
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.searchconsole"
              label="Google Search Console"
              placeholder="google-site-verification=xxxxx"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="updatesearchconsole"
            >
              Update
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Slide 1
          </v-col>
          <v-col>
            <v-file-input
              v-model="slide1tmp"
              label="650 x 240"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="uploadslide1"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="mainpage.slide1"
          class="ma-0 pb-1 px-2 justify-center align-center"
        >
          <v-col cols="1">
            <v-chip
              v-for="(item, index) in mainpage.slide1"
              :key="index"
              class="mb-1"
              color="info"
              @click="removeslide1(index)"
            >
              {{ 'รูปที่ ' + (index + 1) }}
            </v-chip>
          </v-col>
          <v-col
            v-if="mainpage.slide1 && mainpage.slide1.length"
            cols="9"
          >
            <VueSlickCarousel
              class="pt-4 px-2"
              :arrows="false"
              :dots="false"
              autoplay
              :autoplay-speed="2000"
            >
              <v-img
                v-for="(item, index) in mainpage.slide1"
                :key="index"
                contain
                width="40.625rem"
                height="15rem"
                class="rounded"
                :src="'https://cms.deepcdn.net/' + item"
                alt="cover1"
              >
              </v-img>
            </VueSlickCarousel>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Upload Background
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.bg"
              contain
              width="200"
              height="100"
              :src="'https://cms.deepcdn.net/' + mainpage.bg"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="bgtmp"
              label="พื้นหลังจะถูกนำมาเรียงต่อกัน"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!bgtmp"
              color="primary"
              @click="uploadbg"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            PWA Icon
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.pwaicons && mainpage.pwaicons[144]"
              contain
              width="144"
              height="144"
              :src="'https://cms.deepcdn.net/' + mainpage.pwaicons[144]"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="pwatmp"
              label="Icon ขนาด 512x512"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!pwatmp"
              color="primary"
              @click="uploadpwa"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            OG Icon
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.ogicon"
              contain
              :src="'https://cms.deepcdn.net/' + mainpage.ogicon"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="ogicon"
              label="ภาพประกอบเมื่อแชร์ไปยัง Social Platform"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!ogicon"
              color="primary"
              @click="uploadog"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Announcement
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.announce"
              contain
              :src="('https://cms.deepcdn.net/' + mainpage.announce)"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="announce"
              label="ภาพประกาศ 300x700"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="mainpage.announce"
              color="error"
              @click="removeannounce"
            >
              Remove
            </v-btn>
            <v-btn
              v-else
              :disabled="!announce"
              color="primary"
              @click="uploadannounce"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-5 px-5 justify-end align-center">
          <v-btn
            color="success"
            @click="savemainpage"
          >
            Save
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
} from '@mdi/js'
import {

  // TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Image,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import Gallery from '@/components/Gallery.vue'
import Colorpellet from '@/components/Colorpellet.vue'

// import tw from '@/assets/tconfig.json'

export default {
  components: { VueSlickCarousel, Colorpellet }, // , TiptapVuetify },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
      },
    }
  },
  data() {
    return {
      logo: null,
      logotmp: null,
      minilogotmp: null,
      biglogotmp: null,
      bgtmp: null,
      pwatmp: null,
      ogicon: null,
      announce: null,
      bannertmp: null,
      slide1tmp: null,
      slide2tmp: null,
      slide3tmp: null,
      mainpage: {
        ptsapi: '',
        nav: { c: 'red', s: '200' },
        navto: { c: 'red', s: '200' },
        wc: { c: 'red', s: '200' },
        m: { c: 'red', s: '200' },
        mto: { c: 'red', s: '200' },
        mb: { c: 'red', s: '200' },
        mbto: { c: 'red', s: '200' },
        bnav: { c: 'red', s: '200' },
        bnavto: { c: 'red', s: '200' },
        pbg: { c: 'red', s: '200' },
        cbg: { c: 'red', s: '200' },
        cbgto: { c: 'red', s: '200' },
        ht: { c: 'red', s: '200' },
        mt: { c: 'red', s: '200' },
        mbt: { c: 'red', s: '200' },
        bt: { c: 'red', s: '200' },
      },
      colors: [
        { label: 'Red', value: 'red', code: '#ef4444' },
        { label: 'Gray', value: 'gray', code: '#4b5563' },
        { label: 'Yellow', value: 'yellow', code: '#fcd34d' },
        { label: 'Green', value: 'green', code: '#10b981' },
        { label: 'Blue', value: 'blue', code: '#2563eb' },
        { label: 'Indigo', value: 'indigo', code: '#6366f1' },
        { label: 'Purple', value: 'purple', code: '#8b5cf6' },
        { label: 'Pink', value: 'pink', code: '#ec4899' },
      ],
      menubars: [
        { label: 'Red', value: 'red-700', code: '#b91c1c' },
        { label: 'Gray', value: 'gray-900', code: '#111827' },
        { label: 'Yellow', value: 'yellow-300', code: '#fcd34d' },
        { label: 'Green', value: 'green-500', code: '#10b981' },
        { label: 'Blue', value: 'blue-600', code: '#2563eb' },
        { label: 'Indigo', value: 'indigo-500', code: '#6366f1' },
        { label: 'Purple', value: 'purple-500', code: '#8b5cf6' },
        { label: 'Pink', value: 'pink-500', code: '#ec4899' },
      ],
      textcolors: [
        { label: 'Black', value: 'black', code: '#000' },
        { label: 'White', value: 'white', code: '#fff' },
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        [Image,
          {
            options: {
              imageSources: [
                { component: Gallery, name: 'Gallery' },
              ],
            },
          }],
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      zonetexts: [
        { value: 'nav', label: 'แท็บบน' }, //
        { value: 'navto', label: 'แท็บบนรอง' }, //
        { value: 'wc', label: 'พื้นต้อนรับ' }, //
        { value: 'm', label: 'เมนู' }, //
        { value: 'mto', label: 'เมนูรอง' }, //
        { value: 'mb', label: 'ปุ่มเมนู' }, //
        { value: 'mbto', label: 'ปุ่มเมนูรอง' }, //
        { value: 'bnav', label: 'เมนูล่าง' }, //
        { value: 'bnavto', label: 'เมนูล่างรอง' }, //
        { value: 'pbg', label: 'สีพื้น' }, //
        { value: 'cbg', label: 'พื้นหลัง' }, //
        { value: 'cbgto', label: 'พื้นหลังรอง' }, //
        { value: 'ht', label: 'ข้อความ(header)' }, //
        { value: 'mt', label: 'ข้อความ' },
        { value: 'mbt', label: 'ข้อความเมนู' }, //
        { value: 'bt', label: 'ข้อความเมนูล่าง' }, //
      ],
    }
  },
  async created() {
    const { data } = await this.axios.get('/admin/mainpage')
    this.mainpage = data.website

    // console.log(data.website)
    // console.log(tw.theme.accentColor)
    // let cstring = ''
    // const conditions = [
    //   'inherit',
    //   'current',
    //   'transparent',
    //   'black',
    //   'white',
    //   'auto',
    // ]
    // for (const key in tw.theme.accentColor) {
    //   if (!conditions.includes(key)) {
    //     for (const s in tw.theme.accentColor[key]) {
    //       cstring += `text-${key}-${s} `
    //     }
    //   }
    // }
    // console.log(cstring)

    // const tailwindconfig = require('@/assets/tconfig.json')
    // console.log(tailwindconfig.theme.accentColor)

    // theme.accentColor
  },
  methods: {
    scolor(c, s, type) {
      this.mainpage[type] = { c, s }
    },
    async getlineadd() {
      const { data } = await this.axios.get(`${this.mainpage.ptsapi}/api/lookup/contact`)
      console.log(data)
    },
    async savemainpage() {
      if (confirm('ยืนยันการบันทึกหน้าหลัก ?')) {
        await this.axios.put('/admin/mainpage', this.mainpage)
        window.location.reload()
      }
    },
    async updatesearchconsole() {
      if (confirm('ยืนยันการเปลี่ยน Search Console?')) {
        await this.axios.put('/admin/searchconsole', { txt: this.mainpage.searchconsole })
        window.location.reload()
      }
    },
    async uploadlogo() {
      if (this.logotmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.logotmp)
          await this.axios.post('/admin/logo', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.logotmp = null
    },
    async uploadbg() {
      if (this.bgtmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.bgtmp)
          await this.axios.post('/admin/bg', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.bgtmp = null
    },
    async uploadpwa() {
      if (this.pwatmp) {
        if (confirm('update icon ?')) {
          const image = new FormData()
          image.append('img', this.pwatmp)
          await this.axios.post('/admin/pwaicon', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.pwatmp = null
    },
    async uploadog() {
      if (this.ogicon) {
        if (confirm('update icon ?')) {
          const image = new FormData()
          image.append('img', this.ogicon)
          await this.axios.post('/admin/ogicon', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.ogicon = null
    },
    async uploadannounce() {
      if (this.announce) {
        if (confirm('update icon ?')) {
          const image = new FormData()
          image.append('img', this.announce)
          await this.axios.post('/admin/announce', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.announce = null
    },
    async removeannounce() {
      await this.axios.delete('/admin/announce')
      const { data: { website } } = await this.axios.get('/admin/mainpage')
      this.mainpage = website
    },
    async uploadminilogo() {
      if (this.minilogotmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.minilogotmp)
          await this.axios.post('/admin/minilogo', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.minilogotmp = null
    },
    async uploadbiglogo() {
      if (this.biglogotmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.biglogotmp)
          await this.axios.post('/admin/biglogo', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.biglogotmp = null
    },
    async uploadbanner() {
      if (this.bannertmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.bannertmp)
          await this.axios.post('/admin/banner', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.bannertmp = null
    },
    async uploadslide1() {
      if (this.slide1tmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.slide1tmp)
          await this.axios.post('/admin/slide1', image)
          window.location.reload()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.slide1tmp = null
    },
    async uploadslide2() {
      if (this.slide2tmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.slide2tmp)
          await this.axios.post('/admin/slide2', image)
          window.location.reload()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.slide2tmp = null
    },
    async uploadslide3() {
      if (this.slide3tmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.slide3tmp)
          await this.axios.post('/admin/slide3', image)
          window.location.reload()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.slide3tmp = null
    },
    async removeslide1(index) {
      if (confirm('ลบรูปภาพ ?')) {
        await this.axios.delete('/admin/slide1', { params: { index } })
        window.location.reload()
      }
    },
    async removeslide2(index) {
      if (confirm('ลบรูปภาพ ?')) {
        await this.axios.delete('/admin/slide2', { params: { index } })
        window.location.reload()
      }
    },
    async removeslide3(index) {
      if (confirm('ลบรูปภาพ ?')) {
        await this.axios.delete('/admin/slide3', { params: { index } })
        window.location.reload()
      }
    },
  },
}
</script>
