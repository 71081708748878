<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :dark="Number(s) > 400"
          v-bind="attrs"
          :style="{
            backgroundColor: colors[c][s]
          }"
          v-on="on"
        >
          {{ label }}
        </v-btn>
      </template>
      <div :style="{ padding: '0.25rem', backgroundColor: 'white' }">
        <div
          v-for="(color, k) in colors"
          :key="k"
          :style="{ paddingBottom: '0.25rem', display: 'grid', gridTemplateColumns: 'repeat(10, minmax(0, 1fr))', gap: '0.25rem' }"
        >
          <div
            v-for="(shade, key) in color"
            :key="key"
            :style="{
              backgroundColor: shade,
              width: '1.5rem',
              height: '1.5rem'
            }"
            @click="selectcolor(k, key)"
          >
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import tw from '@/assets/tconfig.json'

export default {
  props: {
    type: {
      type: String, default: 'nav',
    },
    label: {
      type: String, default: 'แท็บบนรอง',
    },
    c: {
      type: String, default: 'blue',
    },
    s: {
      type: String, default: '100',
    },
  },
  data() {
    return {
      colors: {
        // slate: tw.theme.accentColor.slate,
        gray: tw.theme.accentColor.gray,

        // zinc: tw.theme.accentColor.zinc,
        // neutral: tw.theme.accentColor.neutral,
        // stone: tw.theme.accentColor.stone,
        red: tw.theme.accentColor.red,
        orange: tw.theme.accentColor.orange,
        amber: tw.theme.accentColor.amber,
        yellow: tw.theme.accentColor.yellow,
        lime: tw.theme.accentColor.lime,
        green: tw.theme.accentColor.green,
        emerald: tw.theme.accentColor.emerald,
        teal: tw.theme.accentColor.teal,
        cyan: tw.theme.accentColor.cyan,
        sky: tw.theme.accentColor.sky,
        blue: tw.theme.accentColor.blue,
        indigo: tw.theme.accentColor.indigo,
        violet: tw.theme.accentColor.violet,
        purple: tw.theme.accentColor.purple,
        fuchsia: tw.theme.accentColor.fuchsia,
        pink: tw.theme.accentColor.pink,
        rose: tw.theme.accentColor.rose,
      },
    }
  },
  methods: {
    selectcolor(c, s) {
      this.$emit('scolor', c, s, this.type)
    },
  },
}
</script>
